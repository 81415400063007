import React from "react";

import { Link, graphql, useStaticQuery } from "gatsby";

import { Container, Content, Title, ImgBox, BgImage, WhatsappIcon, Article } from "./styles";

function Telemedicine() {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "bgImage/bg-telemedicina.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Container>
      <ImgBox>
        <BgImage fluid={banner.childImageSharp.fluid}>
          <Title>Vídeo Consulta e Consulta Domiciliar</Title>
        </BgImage>
      </ImgBox>
      <Content>
        <Article>
          <p>
            <strong>Caros pacientes,</strong>
          </p>
          <p>
            Diante do isolamento social pelo qual estamos passando, devido à pandemia do Covid 19,
            prontifico-me a atender, via vídeo-chamada em:
          </p>
          <ul>
            <li>clínica geral</li>
            <li>tratamento da dor,</li>
            <li>medicina integrativa</li>
            <li>
              <Link to="/medicina-tradicional-chinesa">medicina chinesa</Link>
            </li>
          </ul>
          <p>
            O objetivo é realizar diagnóstico, esclarecer dúvidas, fornecer orientações e
            prescrições médicas aos pacientes.
          </p>
          <p>
            Caso haja necessidade de realização do exame físico minucioso, fico à disposição para o
            atendimento domiciliar ou na clínica, seguindo, sempre, as normas de segurança e higiene
            (uso de máscaras e assepsia).
          </p>
          <p>
            Uma vez que a Medicina Tradicional Chinesa privilegia uma visão global do paciente, dou
            orientação em nutrição/dieta e mudança de hábitos, além de meditação, técnicas de
            relaxamento, exercícios respiratórios e, principalmente, a prescrição de medicamentos
            fitoterápicos para auxiliar na prevenção de doenças, na melhora global da saúde e da
            imunidade.
          </p>
          <p>Contem comigo neste momento delicado que estamos enfrentando.</p>
          <p>
            Um grande abraço. <br /> Atenciosamente,
          </p>
          <strong>
            <Link to="/sobre">Helena Campiglia.</Link>
          </strong>
          <div className="button">
            <Link to="https://wa.me/5511982288192?text=sua%20mensagem" target="_blank">
              <span>
                <WhatsappIcon /> Entre em contato
              </span>
            </Link>
          </div>
        </Article>
      </Content>
    </Container>
  );
}

export default Telemedicine;
