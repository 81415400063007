import React from "react";
/* eslint-disable max-len */

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Telemedicina from "../components/template-parts/Telemedicine";

const Telemedicine = () => (
  <Layout>
    <SEO
      title="Telemedicina"
      keywords="isolamento social, telemedicina, medicina chinesa, tratamento da dor, clínica geral, covid-19, nutrição, mudança de hábitos"
      description="O objetivo é realizar diagnóstico, esclarecer dúvidas, fornecer orientações e prescrições médicas aos pacientes."
    />
    <Telemedicina />
  </Layout>
);

export default Telemedicine;
